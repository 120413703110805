<template>
  <div class="content_container">

    <el-breadcrumb
      separator-class="el-icon-arrow-right "
      class="titleNav"
    >

      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页 </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>《仪商城服务条款》</el-breadcrumb-item>
    </el-breadcrumb>

    <div
      class="cont"
      v-html='article_content'
    >

    </div>

  </div>

</template>

<script type="text/javascript">
export default {
  data() {
    return {
      article_content: ""
    };
  },
  filters: {},
  methods: {},
  components: {},
  created() {
    this.$get("home/article/23").then(res => {
      this.article_content = res.article_content;
      console.log("must", this.must);
    });
  }
};
</script>


<style lang="scss" scoped>
@import "@/assets/styles/mixins";

dl {
  width: 100%;
  margin-bottom: 20px;
  @include space-between;
  dt {
    width: 100px;
  }
  dd {
    flex: 1;
  }
}
.cont {
  padding: 20px 100px;
  background: #fff;
  line-height: 30px;
  min-height: 600px;
  box-sizing: border-box;
}
::v-deep .el-form-item {
  margin-bottom: 2px;
}
.content_container {
  min-height: auto !important;
}

.titleNav {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background: #fff;
}
</style>
